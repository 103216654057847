import { Link } from 'react-router-dom'
import React from 'react'
import line from '../../assets/images/anim_line.png'
import blueapp from '../../assets/images/appstore_blue.png'
import blue from '../../assets/images/googleplay_blue.png'
import logo from '../../assets/images/footer_logo.png'
import top from '../../assets/images/go_top.png'

const Main = ({ footer }) => {
  return (
    <footer>
      <div className="top_footer" id="contact">
        <div className="anim_line dark_bg">
          <span><img src={line} alt="anim_line" /></span>
          <span><img src={line} alt="anim_line" /></span>
          <span><img src={line} alt="anim_line" /></span>
          <span><img src={line} alt="anim_line" /></span>
          <span><img src={line} alt="anim_line" /></span>
          <span><img src={line} alt="anim_line" /></span>
          <span><img src={line} alt="anim_line" /></span>
          <span><img src={line} alt="anim_line" /></span>
          <span><img src={line} alt="anim_line" /></span>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="abt_side">
                <div className="logo"><img src={logo} alt="image" /></div>
                <ul>
                  <li><Link to="#">mkt@matsuya.com.br</Link></li>
                </ul>
                <ul className="social_media">
                  <li><a href="https://www.facebook.com/restaurantes.matsuya"><i className="icofont-facebook"></i></a></li>
                  <li><a href="https://www.instagram.com/restaurantes.matsuya"><i className="icofont-instagram"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="links">
                <h3>Links Úteis</h3>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/pricing">Benefícios</Link></li>
                  <li><Link to="/contact">Fale conosco</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-12">
              <div className="try_out">
                <h3>Baixe agora</h3>
                <ul className="app_btn">
                  <li>
                    <a href="https://apps.apple.com/br/app/matsuya/id6445817342" target='_blank' rel="noreferrer">
                      <img src={blueapp} alt="image" />
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=app.matsuya" target='_blank' rel="noreferrer">
                      <img src={blue} alt="image" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>© Copyrights 2023. Todos os direitos reservados.</p>
            </div>
            <div className="col-md-6">
              <p className="developer_text">Criado & desenvolvido por Matsuya | Restaurante Japonês</p>
            </div>
          </div>
        </div>
      </div>
      <div className="go_top">
        <span><img src={top} alt="image" /></span>
      </div>
    </footer>
  )
}

export default Main
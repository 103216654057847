import OwlCarousel from 'react-owl-carousel'
import React from 'react'
import user4 from '../../../assets/images/avtar_testimonial.png'

const Main = () => {
  const testimonial_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  }
  return (
    <>
      <section className="row_am testimonial_section">
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
            <h2>O que nossos <span>assinantes dizem</span></h2>
            <p>Confira abaixo o depoimentos de alguns de nossos assinantes que já utilizam nosso app</p>
          </div>
          <div className="testimonial_block" data-aos="fade-in" data-aos-duration="1500">
            <OwlCarousel id="testimonial_slider" {...testimonial_slider} className="owl-carousel owl-theme owl-loaded owl-drag">
              <div className="item">
                <div className="testimonial_slide_box">
                  <div className="rating">
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                  </div>
                  <p className="review">
                    “ O aplicativo é simplesmente fantástico! Utilizo há pouco mais de 2 meses e tem sido extremamente satisfatório. ”
                  </p>
                  <h3>Bianca Neves</h3>
                  <span className="designation">Empresária</span>
                </div>
              </div>
              <div className="item">
                <div className="testimonial_slide_box">
                  <div className="rating">
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                  </div>
                  <p className="review">
                    “ Comecei a usar há algumas semanas e me apaixonei. Não deixo de usar de jeito nenhum!. ”
                  </p>
                  <h3>Gustavo Monarch</h3>
                  <span className="designation">Advogado</span>
                </div>
              </div>
              <div className="item">
                <div className="testimonial_slide_box">
                  <div className="rating">
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                    <span><i className="icofont-star"></i></span>
                  </div>
                  <p className="review">
                    “ Aplicativo intuitivo, rápido e de fácil entendimento. Passei a consumir muito mais depois do Matclub, super indico! ”
                  </p>
                  <h3>Marcos Eduardo</h3>
                  <span className="designation">Personal Trainer</span>
                </div>
              </div>
            </OwlCarousel>
            <div className="avtar_faces">
              <img src={user4} alt="image" />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
import React from 'react'
import frame from '../../../assets/images/about-frame.png'
import screen from '../../../assets/images/about-screen.png'
import img1 from '../../../assets/images/download.png'
import img2 from '../../../assets/images/followers.png'
import img3 from '../../../assets/images/reviews.png'
import img4 from '../../../assets/images/countries.png'

const Main = ({ video, dark }) => {
  return (
    <>
      <section className="row_am about_app_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                <div className="frame_img">
                  <img className="moving_position_animatin" src={frame} alt="image" />
                </div>
                <div className="screen_img">
                  <img className="moving_animation" src={screen} alt="image" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_text">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                  <h2>Algumas informações <span>interessantes.</span></h2>
                  <p>Confira algumas informações e curiosidades sobre nossa marca</p>
                </div>
                <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                  <li>
                    <div className="icon">
                      <img src={img1} alt="image" />
                    </div>
                    <div className="text">
                      <p><span className="counter-value" data-count="12"></span><span>mil</span></p>
                      <p>Assinantes</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={img2} alt="image" />
                    </div>
                    <div className="text">
                      <p><span className="counter-value" data-count="120">120</span><span>mil</span></p>
                      <p>Seguidores</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={img3} alt="image" />
                    </div>
                    <div className="text">
                      <p><span className="counter-value" data-count="1500">1500</span></p>
                      <p>Avaliações</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={img4} alt="image" />
                    </div>
                    <div className="text">
                      <p><span className="counter-value" data-count="18">18</span></p>
                      <p>Unidades</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
import logo from "../../assets/images/footer_logo.png";
import logo1 from "../../assets/images/logo.png";

const Main = ({ navbar }) => {
  const location = useLocation()
  const path = location.pathname

  const [show, setShow] = useState()
  const [show1, setShow1] = useState()
  const [show2, setShow2] = useState()
  const [mobile, setmobile] = useState()

  useEffect(() => {
    window.scroll(0, 0)
  }, [path]);

  return (
    <>
      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link to="/" className="navbar-brand">
              <img src={logo1} alt="image" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <div className={`toggle-wrap ${mobile && "active"}`} onClick={() => setmobile(mobile === true ? false : true)}>
                <span className="toggle-bar"></span>
              </div>
            </button>
            <div className="collapse navbar-collapse" >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link smooth to="/" className="nav-link">Home</Link>
                </li>
                <li className="nav-item">
                  <Link smooth to="#features" className="nav-link">Diferenciais</Link>
                </li>
                <li className="nav-item">
                  <Link smooth to="#how_it_work" className="nav-link">Como funciona</Link>
                </li>
                <li className="nav-item">
                  <Link to="/pricing" className="nav-link">Benefícios</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">Contato</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link dark_btn">DÚVIDAS?</Link>
                </li>
              </ul>
            </div>
            <div className={`collapse navbar-collapse mobile-screen ${mobile && "show"}`}  >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link smooth to="/" className="nav-link">Home</Link>
                </li>
                <li className="nav-item">
                  <Link smooth to="#features" className="nav-link">Diferenciais</Link>
                </li>
                <li className="nav-item">
                  <Link smooth to="#how_it_work" className="nav-link">Como funciona</Link>
                </li>
                <li className="nav-item">
                  <Link to="/pricing" className="nav-link">Benefícios</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">Contato</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link dark_btn">DÚVIDAS?</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Main
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg from "../../assets/images/bread_crumb_bg.png"

const Main = ({ brdcum }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const sendMessage = async () => {
    if (name !== "" && email !== "" && phone !== "" && message !== "") {
      const text = `Olá, gostaria de mais informações sobre o app: %0a%0a Nome: ${name} %0a Email: ${email} %0a Telefone: ${phone} %0a Mensagem: ${message}`

      window.open(`https://api.whatsapp.com/send?phone=5511931451955&text=${text}`, '_blank', 'noreferrer');
    }
  }

  return (
    <>
      <Bredcrumb
        no={1}
        title="Fale Conosco"
        paragraph="Informe sua dúvida ou informação e entraremos em contato."
        tag="Fale Conosco"
        bgimg={BGImg}
      />

      <section className="contact_page_section" id="contact">
        <div className="container">
          <div className="contact_inner">
            <div className="contact_form">
              <div className="section_title">
                <h2>Deixe sua <span>mensagem</span></h2>
                <p>Preencha os campos abaixo</p>
              </div>
              <form id="contact_form" action="#">
                <div className="form-group">
                  <input type="text" placeholder="Nome" className="form-control" onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="form-group">
                  <input type="email" placeholder="Email" className="form-control" onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="form-group">
                  <input type="text" placeholder="Celular" className="form-control" onChange={(e) => setPhone(e.target.value)} required />
                </div>
                <div className="form-group">
                  <textarea className="form-control" placeholder="Sua mensagem" onChange={(e) => setMessage(e.target.value)} required></textarea>
                </div>
                <div className="form-group mb-0">
                  <button type="submit" className="btn puprple_btn" onClick={sendMessage}>ENVIAR MENSAGEM</button>
                </div>
              </form>
            </div>
            <div className="contact_info">
              <div className="icon"><img src="assets/images/contact_message_icon.png" alt="image" /></div>
              <div className="section_title">
                <h2>Tem alguma <span>dúvida?</span></h2>
                <p>Se você possui alguma dúvida sobre nosso aplicativo, visite a seção "Como funciona".</p>
              </div>
              <Link to="/#how_it_work" className="btn puprple_btn">DÚVIDAS</Link>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img src="assets/images/mail_icon.png" alt="image" />
                  </div>
                  <div className="text">
                    <span>Nosso email</span>
                    <a href='mailto:contato@matsuya.com.br'>mkt@matsuya.com.br</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
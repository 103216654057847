import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Pricing from '../HomeMain/Pricing/Main'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg from "../../assets/images/bread_crumb_bg.png"
import BGImg1 from "../../assets/images/bread_crumb_bg_one.png"
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"


const Main = ({ brdcum }) => {
  const [activeFaq, setActiveFaq] = useState({ a: true })

  return (
    <>
      <Bredcrumb
        no={1}
        title="Nossos planos disponíveis"
        paragraph="Confira abaixo nossos planos disponíveis e seus benefícios"
        tag="Nossos planos disponíveis"
        bgimg={BGImg} />

      <Pricing />
      <section className="row_am faq_section">
        <div className="container">
          <div className="faq_panel">
            <div className="accordion" id="accordionExample">
              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button type="button" className={`btn btn-link ${activeFaq.a && "active"}`} onClick={() => setActiveFaq(activeFaq.a ? { a: false } : { a: true })} data-toggle="collapse" data-target="#collapseOne">
                      {activeFaq.a ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>} Eu preciso pagar ?</button>
                  </h2>
                </div>
                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Não! O aplicativo é 100% gratuito e qualquer um pode utilizá-lo. Os valores de nossos planos são baseados em seu histórico de consumo!</p>
                  </div>
                </div>
              </div>

              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingTwo">
                  <h2 className="mb-0">
                    <button type="button" className={`btn btn-link ${activeFaq.b && "active"}`} onClick={() => setActiveFaq(activeFaq.b ? { b: false } : { b: true })} data-toggle="collapse"
                      data-target="#collapseTwo">{activeFaq.b ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>} Como crio minha conta ?</button>
                  </h2>
                </div>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Baixe o aplicativo na loja de seu celular e siga o passo a passo, é bem simples e prático!</p>
                  </div>
                </div>
              </div>

              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button type="button" className={`btn btn-link ${activeFaq.c && "active"}`} onClick={() => setActiveFaq(activeFaq.c ? { c: false } : { c: true })} data-toggle="collapse"
                      data-target="#collapseThree">{activeFaq.c ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}Meu valor de cashback fica disponível para sempre ?</button>
                  </h2>
                </div>
                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Não, você terá um prazo de 60 dias para utilizar o valor que possui em Cashback!</p>
                  </div>
                </div>
              </div>

              <div className="card" data-aos="fade-up" data-aos-duration="1500">
                <div className="card-header" id="headingFour">
                  <h2 className="mb-0">
                    <button type="button" className={`btn btn-link ${activeFaq.d && "active"}`} onClick={() => setActiveFaq(activeFaq.d ? { d: false } : { d: true })} data-toggle="collapse"
                      data-target="#collapseFour">{activeFaq.d ? <i className="icon_faq icofont-minus"></i> : <i className="icon_faq icofont-plus"></i>}Após 60 dias eu consigo novos valores de cashback ?</button>
                  </h2>
                </div>
                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                  <div className="card-body">
                    <p>Sim, você poderá sempre utilizar seu valor em cashback dentro do prazo de 60 dias. Após esse período, novos valores que você consumir entrarão diretamente em sua carteira digital e terão novamente esse prazo para consumo.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
import React from 'react'
import img1 from '../../../assets/images/secure_data.png'
import img2 from '../../../assets/images/functional.png'
import img3 from '../../../assets/images/live-chat.png'
import img4 from '../../../assets/images/support.png'
import img5 from '../../../assets/images/features_frame.png'
import celphoneImage from '../../../assets/images/matsuya-screen-mockup1.png'

const Main = ({ video }) => {
  return (
    <section className="row_am features_section" id="features">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
          <h2><span>Funcionalidades</span> que tornam o app exclusivo!</h2>
          <p>Nosso aplicativo conta com funcionalidades e funções exclusivas para você assinante</p>
        </div>
        <div className="feature_detail">
          <div className="left_data feature_box">
            <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
              <div className="icon">
                <img src={img1} alt="image" />
              </div>
              <div className="text">
                <h4>Seus dados seguros</h4>
                <p>Seus dados estarão 100% seguros</p>
              </div>
            </div>
            <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
              <div className="icon">
                <img src={img2} alt="image" />
              </div>
              <div className="text">
                <h4>Funcional e Intuitivo</h4>
                <p>Nosso aplicativo foi pensado para tornar sua experiência o mais agradável possível</p>
              </div>
            </div>
          </div>
          <div className="right_data feature_box">
            <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
              <div className="icon">
                <img src={img3} alt="image" />
              </div>
              <div className="text">
                <h4>Benefícios exclusivos</h4>
                <p>Assinantes contam com benefícios e descontos exclusivos</p>
              </div>
            </div>
            <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
              <div className="icon">
                <img src={img4} alt="image" />
              </div>
              <div className="text">
                <h4>Suporte Personalizado</h4>
                <p>Conte com uma equipe de suporte para lhe ajudar em caso de dúvidas.</p>
              </div>
            </div>
          </div>
          <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
            <img src={celphoneImage} alt="image" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
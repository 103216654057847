import React from 'react'
import { Link } from 'react-router-dom'
import img from '../../../assets/images/download_app.jpg'
import img1 from '../../../assets/images/create_account.jpg'
import img2 from '../../../assets/images/enjoy_app.jpg'

const Main = ({ dark }) => {
  return (
    <>
      <section className="row_am how_it_works" id="how_it_work">
        <div className="container">
          <div className={`how_it_inner ${dark && "dark"}`}>
            <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
              <h2><span>Como funciona?</span><br></br> Simples, em apenas 3 passos!</h2>
              <p>Confira abaixo um passo a passo de como o app funciona</p>
            </div>
            <div className="step_block">
              <ul>
                <li>
                  <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                    <h4>Baixe o aplicativo</h4>
                    <div className="app_icon">
                      <Link to="#"><i className="icofont-brand-android-robot"></i></Link>
                      <Link to="#"><i className="icofont-brand-apple"></i></Link>
                      <Link to="#"><i className="icofont-brand-windows"></i></Link>
                    </div>
                    <p>Baixe o aplicativo em seu smartphone iOS ou Android.</p>
                  </div>
                  <div className="step_number">
                    <h3>01</h3>
                  </div>
                  <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                    <img src={img} alt="image" />
                  </div>
                </li>
                <li>
                  <div className="step_text" data-aos="fade-left" data-aos-duration="1500">
                    <h4>Crie sua conta</h4>
                    <span>É grátis e rápido!</span>
                    <p>No seu primeiro acesso, crie sua conta rapidamente.</p>
                  </div>
                  <div className="step_number">
                    <h3>02</h3>
                  </div>
                  <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                    <img src={img1} alt="image" />
                  </div>
                </li>
                <li>
                  <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                    <h4>Pronto! Você já será um Matcluber.</h4>
                    <span>Alguma dúvida? Clique e <Link to="/contact">fale conosco</Link></span>
                    <p>Siga as instruções do app e tenha a melhor experiência do nosso clube de assinatura!</p>
                  </div>
                  <div className="step_number">
                    <h3>03</h3>
                  </div>
                  <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                    <img src={img2} alt="image" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
import React, { useState } from 'react'
import img1 from '../../../assets/images/standard.png'
import img2 from '../../../assets/images/unlimited.png'
import img3 from '../../../assets/images/premium.png'

const Main = ({ gredient, video }) => {
  const [tog] = useState()

  return (
    <section className="row_am pricing_section" id="pricing">
      <div className="container">
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          <h2>Utilize e alcance novos <span>planos</span></h2>
          <p>Quanto mais você usar e consumir, maior será seu plano e seus benefícios!</p>
        </div>
        <div className={`pricing_pannel monthly_plan ${tog ? '' : 'active'}`}>
          <div className="row">
            <div className="col-md-4">
              <div className="pricing_block">
                <div className="icon">
                  <img src={img1} alt="Matsuya Bronze Icon" />
                </div>
                <div className="pkg_name">
                  <h3>Matsuya Bronze</h3>
                  <span>Nosso plano inicial</span>
                </div>
                <span className="price">R$ 0 até R$ 499</span>
                <ul className="benifits">
                  <li>
                    <p className="cashback">Cashback de 5% sobre o valor gasto na loja</p>
                  </li>
                  <li>
                    <p>Período de 365 dias corridos a partir da entrada no plano</p>
                  </li>
                  <li>
                    <p>Você utiliza o saldo de sua carteira digital como quiser para pagar sua conta no restaurante</p>
                  </li>
                  <li>
                    <p>30% de desconto no rodízio tradicional no mês do seu aniversário</p>
                  </li>
                  <li>
                    <p>Você tem até 180 dias para consumir o valor de cashback</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing_block highlited_block">
                <div className="icon">
                  <img src={img2} alt="Matsuya Prata Icon" />
                </div>
                <div className="pkg_name">
                  <h3>Matsuya Prata</h3>
                  <span>Plano intermediário</span>
                </div>
                <span className="price">R$ 499 até R$ 999</span>
                <ul className="benifits">
                  <li>
                    <p className="cashback">Cashback de 8% sobre o valor gasto na loja</p>
                  </li>
                  <li>
                    <p>Período de 365 dias corridos a partir da entrada no plano</p>
                  </li>
                  <li>
                    <p>Você utiliza o saldo de sua carteira digital como quiser para pagar sua conta no restaurante</p>
                  </li>
                  <li>
                    <p>50% de desconto no rodízio tradicional no mês do seu aniversário</p>
                  </li>
                  <li>
                    <p>Até 180 dias para consumir o valor do cashback</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pricing_block">
                <div className="icon">
                  <img src={img3} alt="Matsuya Ouro Icon" />
                </div>
                <div className="pkg_name">
                  <h3>Matsuya Ouro</h3>
                  <span>O verdadeiro Matcluber!</span>
                </div>
                <span className="price">R$ 999 até R$ 1499</span>
                <ul className="benifits">
                  <li>
                    <p className="cashback">Cashback de 10% sobre o valor gasto na loja</p>
                  </li>
                  <li>
                    <p>Período de 365 dias corridos a partir da entrada no plano</p>
                  </li>
                  <li>
                    <p>Você utiliza o saldo de sua carteira digital como quiser para pagar sua conta no restaurante</p>
                  </li>
                  <li>
                    <p>Um rodízio tradicional grátis no mês do seu aniversário</p>
                  </li>
                  <li>
                    <p>Até 180 dias para consumir o valor do cashback</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Main
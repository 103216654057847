import React from 'react'
import img1 from '../../../assets/images/modern01.png'
import img2 from '../../../assets/images/secure_data.png'
import img3 from '../../../assets/images/modern02.png'
import img4 from '../../../assets/images/modern03.png'

const Main = ({ gredient }) => {
  return (
    <>
      <section className="row_am modern_ui_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ui_text">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                  <h2>Design elegante e <span>moderno</span></h2>
                  <p>Nosso aplicativo foi pensando de forma estratégica, e seu design diz muito sobre nossa marca e nosso estilo próprio. Nele você se sentirá em casa!</p>
                </div>
                <ul className="design_block">
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <h4>Pensado em tudo</h4>
                    <p>Nosso aplicativo foi 100% pensado e estudado do início ao fim</p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <h4>Usuário sempre à frente</h4>
                    <p>Em nosso app, a usabilidade vem em primeiro lugar! Você encontrará tudo o que precisa facilmente</p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <h4>Velocidade e fluidez</h4>
                    <p>Conte com um aplicativo leve e fluído, para lhe proporcionar a melhor experiência possível</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                <div className="left_img">
                  <img className="moving_position_animatin" src={img1} alt="image" />
                </div>
                <div className="right_img">
                  <img className="moving_position_animatin" src={img2} alt="image" />
                  <img className="moving_position_animatin" src={img3} alt="image" />
                  <img className="moving_position_animatin" src={img4} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
import { HashLink as Link } from 'react-router-hash-link';
import React, { useEffect } from 'react'
import AOS from "aos";
import OwlCarousel from 'react-owl-carousel'
import Features from '../HomeMain/Features/Main'
import AboutApp from '../HomeMain/AboutApp/Main'
import Design from '../HomeMain/Design/Main'
import Work from '../HomeMain/Work/Main'
import Testimonial from '../HomeMain/Testimonial/Main'
import Pricing from '../HomeMain/Pricing/Main'
import Faq from '../HomeMain/Faq/Main'
import Interface from '../HomeMain/Interface/Main'
import Download from '../HomeMain/Download/Main'

import anim from '../../assets/images/anim_line.png'
import blueapp from '../../assets/images/appstore_blue.png'
import whiteapp from '../../assets/images/appstore_white.png'
import blue from '../../assets/images/googleplay_blue.png'
import white from '../../assets/images/googleplay_white.png'
import msg from '../../assets/images/message_icon.png'
import shield from '../../assets/images/shield_icon.png'
import matsuyaScreen from '../../assets/images/matsuya-screen.png'
import matsuyaScreen6 from '../../assets/images/matsuya-screen6.png'
import matsuyaScreen7 from '../../assets/images/matsuya-screen7.png'
import frame from '../../assets/images/mobile_frame_svg.svg'

const Main = ({ setfooter, setnavbar, setbrdcum }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setfooter({ f1: true })
    setnavbar({ n2: true })
    setbrdcum({ b1: true })

    localStorage.setItem("navbar", "home")
  }, [])

  const frmae_slider = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  }

  return (
    <>
      <section className="banner_section home-banner">
        <div className="container">
          <div className="anim_line">
            <span><img src={anim} alt="anim_line" /></span>
            <span><img src={anim} alt="anim_line" /></span>
            <span><img src={anim} alt="anim_line" /></span>
            <span><img src={anim} alt="anim_line" /></span>
            <span><img src={anim} alt="anim_line" /></span>
            <span><img src={anim} alt="anim_line" /></span>
            <span><img src={anim} alt="anim_line" /></span>
            <span><img src={anim} alt="anim_line" /></span>
            <span><img src={anim} alt="anim_line" /></span>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration="1500">
              <div className="banner_text">
                <h1>O Matsuya agora também é <span>Matclub</span></h1>
                <p>Agora você pode ser um Matcluber! Um Clube de Fidelidade completo e com muitos benefícios para você!</p>
              </div>
              <ul className="app_btn">
                <li>
                  <a href="https://apps.apple.com/br/app/matsuya/id6445817342" target='_blank' rel="noreferrer">
                    <img className="blue_img" src={blueapp} alt="image" />
                    <img className="white_img" src={whiteapp} alt="image" />
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=app.matsuya" target='_blank' rel="noreferrer">
                    <img className="blue_img" src={blue} alt="image" />
                    <img className="white_img" src={white} alt="image" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration="1500">
              <div className="banner_slider">
                <div className="left_icon">
                  <img src={msg} alt="image" />
                </div>
                <div className="right_icon">
                  <img src={shield} alt="image" />
                </div>
                <OwlCarousel id="frmae_slider" className="owl-carousel owl-theme owl-loaded owl-drag" {...frmae_slider}>
                  <div className="item">
                    <div className="slider_img">
                      <img src={matsuyaScreen6} alt="image" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src={matsuyaScreen} alt="image" />
                    </div>
                  </div>
                </OwlCarousel>
                <div className="slider_frame">
                  <img src={frame} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Features />
      <AboutApp />
      <Design />
      <Work />
      <Pricing />
      <Faq />
      <Interface />
      <Download />
      <Testimonial />
    </>
  )
}

export default Main